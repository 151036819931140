<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <!-- legal -->
        <b-col md="6">
          <b-form-group label="Forme juridique" label-for="legal">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="legal"
                v-model="legal"
                name="legal"
                placeholder="Ex: ONG, ASBL, SARL,..."
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ legal -->

        <b-col md="6">
          <b-form-group label="ID Nat." label-for="idnat">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="idnat"
                v-model="idnat"
                name="idnat"
                placeholder="N° d'Identification Nationale"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!-- new password -->
        <b-col md="6">
          <b-form-group label-for="rccm" label="RCCM">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="rccm"
                v-model="rccm"
                name="rccm"
                placeholder="N° Registre du Commerce et du Crédit Mobilier"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="6">
          <b-form-group label-for="impot" label="N° Impot">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="impot"
                v-model="impot"
                name="impot"
                placeholder="Votre Numéro Impot"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="postCorporationSettingOfficialForm"
          >
            Enregistrer
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mt-1"
          >
            Annuler
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { $themeConfig } from "@themeConfig";
import axios from "axios";
const userData = JSON.parse(localStorage.getItem("userData"));

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  props: {
    officialData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    //
    return {
      localOptions: JSON.parse(JSON.stringify(this.officialData)),
      sku_corporation: userData.sku_corporation,
      legal: JSON.parse(JSON.stringify(this.officialData)).legal, //
      idnat: JSON.parse(JSON.stringify(this.officialData)).idnat,
      rccm: JSON.parse(JSON.stringify(this.officialData)).rccm,
      impot: JSON.parse(JSON.stringify(this.officialData)).impot,
      errors: [],
    };
  },
  methods: {
    async postCorporationSettingOfficialForm() {
      // for the web app //
      let formData = new FormData();
      formData.append("sku_corporation", this.sku_corporation);
      formData.append("rccm", this.rccm);
      formData.append("impot", this.impot);
      formData.append("idnat", this.idnat);
      formData.append("legal", this.legal);
      //
      await myApi
        .post("update_corporation_infos", formData)
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 10000,
              showCancelButton: true,
              confirmButtonText: "Continuer",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-warning ml-1",
              },
              buttonsStyling: false,
            });
          } else {
            this.$swal({
              title: "Erreur Survenu",
              text: response.data.message,
              icon: "error",
              timer: 10000,
              showCancelButton: false,
              confirmButtonText: "Continuer",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "btn btn-warning",
                cancelButton: "btn btn-outline-error ml-1",
              },
              buttonsStyling: false,
            });
          }
        })
        .catch((error) => {
          this.$swal({
            title: "Erreur Survenu",
            text: error.message,
            icon: "error",
            timer: 10000,
            showCancelButton: false,
            confirmButtonText: "Continuer",
            cancelButtonText: "Annuler",
            customClass: {
              confirmButton: "btn btn-warning",
              cancelButton: "btn btn-outline-error ml-1",
            },
            buttonsStyling: false,
          });
        });
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData));
    },
  },
};
</script>
